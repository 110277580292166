import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// import Img from 'gatsby-image'
import { GatsbyImage } from "gatsby-plugin-image"

import documentToReact from '../../lib/contentful/documentToReact';
import { richTextParser } from '../util'

export default (props) => {
  const { matchedQuery } = props
  const data = props.data[props.componentId];
  const indicationIcon = (data && data.indicationIcon && data.indicationIcon.gatsbyImageData);
  const textImage = (data && data.textImage && data.textImage.gatsbyImageData);
  const title = (data && data.title);
  const direction = (data && data.direction || 'row');
  const oppositeDirection = (direction === 'row') ? 'row-reverse' : 'row';
  const isDesktop = (matchedQuery === 'xl' || matchedQuery === 'lg')
  const textAlign = (direction === 'row') ? 'right' : 'left'
  const titleAlign = (isDesktop) ? 'center' : 'center';
  const hasText = Boolean(data && data.text && data.text.raw)
  return (
    <div style={{overflowX: 'hidden', contentVisibility: 'auto'}}>

    <Box m={3}>
    <Grid container spacing={3} direction={direction} alignItems="center" justify="center">
      <Grid item lg={3} xl={3} md={12} sm={12} xs={12}>
        <Grid container alignItems="center">
          <Grid item lg={12} xl={12} md={6} sm={6} xs={6}>
            <div style={{padding: '2rem'}}>
              {indicationIcon && <GatsbyImage image={indicationIcon} />}
            </div>
          </Grid>
          <Grid item lg={12} xl={12} md={6} sm={6} xs={6}>
            {title && <Typography variant="h3" component="h2" align={titleAlign}>{title}</Typography>}
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={9} xl={9} md={12} sm={12} xs={12}>
        <Grid container direction={oppositeDirection} alignItems="center" spacing={1}>
          <Grid item lg={3} xl={3} md={3} sm={12} xs={12}>
            {textImage && <GatsbyImage image={textImage} />}
          </Grid>
          <Grid item lg={9} xl={9} md={9} sm={12} xs={12}>
            {hasText && documentToReact(richTextParser(data.text.raw), { align: textAlign })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </Box>
    </div>
  )
}